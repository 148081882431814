.Standout {
  max-width: calc(793rem / 16);
  margin: 0 auto;
  padding: clamp(16px, calc(32vw / 7.68), 32px) clamp(16px, calc(36vw / 7.68), 36px);
  border-radius: 4px;
  background: var(--primary-200);
  h2 {
    margin-bottom: clamp(12px, calc(20vw / 7.68), 20px);
  }
}

.StepsGrid {
  padding: clamp(24px, calc(36vw / 7.68), 36px) 0;
  background-color: var(--primary-200);

  header {
    max-width: calc(793rem / 16);
    margin: 0 auto clamp(32px, calc(76vw / 7.68), 96px);
    text-align: center;

    h2 {
      margin-bottom: clamp(12px, calc(28vw / 7.68), 36px);
    }

    .paragraph {
      &>*:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: clamp(24px, calc(36vw / 7.68), 48px);
    counter-reset: counter;

    .item {
      position: relative;
      text-align: center;
      counter-increment: counter;

      >a {
        position: absolute;
        z-index: 2;
        inset: 0;
        display: block;
      }

      a:hover~.img img {
        transform: scale(1.05);
      }

      &:nth-child(3n + 1) {
        .img::before {
          background: var(--primary-300);
        }
      }

      &:nth-child(3n + 2) {
        .img::before {
          background: var(--primary-400);
        }
      }

      &:nth-child(3n + 3) {
        .img::before {
          background: var(--primary-700);
          color: var(--primary-100);
        }
      }

      .img {
        position: relative;

        &::before {
          content: counter(counter);
          position: absolute;
          left: 0;
          bottom: 0;
          width: clamp(48px, calc(48vw / 7.68), 60px);
          height: clamp(48px, calc(48vw / 7.68), 60px);
          font-size: clamp(calc(40rem/16), calc(40vw/7.68), calc(48rem/16));
          font-family: var(--font-city-streetwear);
          border-radius: 50%;
          line-height: 1.6;
          text-align: center;
          z-index: 1;
        }

        padding-left: 20px;
        padding-bottom: 16px;
        z-index: 1;
        position: relative;

        .img-wrap {
          position: relative;
          z-index: 0;
          overflow: hidden;
          border-radius: 4px;

          img {
            transition: transform 0.3s var(--easing);
            aspect-ratio: 381/276;
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }
      }

      .ArrowIcon {
        z-index: -1;
        position: absolute;
        top: -64px;
        left: 62%;
        transform: rotate(-13deg);
      }

      @media (min-width: 950px) {
        &:nth-child(3n) {
          .ArrowIcon {
            display: none;
          }
        }

        &:nth-child(3n + 2) {
          .ArrowIcon {
            top: unset;
            bottom: -48px;
            transform: rotateX(180deg) rotate(-15deg);
          }
        }
      }

      h3 {
        margin: clamp(12px, calc(16vw / 7.68), 24px) 0 clamp(8px, calc(12vw / 7.68), 12px);
      }
    }

    @media (max-width: 949px) {
      grid-template-columns: 1fr 1fr;

      .item {
        &:nth-child(2n) {
          .ArrowIcon {
            display: none;
          }
        }
      }
    }

    @media (max-width: 649px) {
      grid-template-columns: 1fr;

      .ArrowIcon {
        display: none;
      }
    }
  }
}
.RelatedMaterials {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: clamp(20px, calc(32vw / 7.68), 32px) 48px;
  padding: clamp(20px, calc(32vw / 7.68), 32px) clamp(16px, calc(32vw / 7.68), 32px);
  border-radius: 4px;
  border: 1px solid var(--primary-400, #e5d8d4);
  header {
    max-width: 478px;
    flex-grow: 1;
    h2 {
      margin-bottom: 20px;
      font-size: clamp(calc(24rem / 16), calc(36vw / 7.68), calc(36rem / 16));
      line-height: 1.2;
    }
    p {
      line-height: 1.6;
    }
  }

  > div {
    @media (min-width: 541px) {
      a {
        margin: 36px 0 0 0 !important;
      }
    }
  }

  @media (max-width: 1189px) {
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }

  @media (max-width: 709px) {
    .container {
      flex-direction: column;
      align-items: center;

      .box {
        align-items: center;
      }
    }
  }
}

.UnorderedList {
  header {
    max-width: calc(793rem / 16);
    margin: 0 auto;
    text-align: center;
    margin-bottom: clamp(16px, calc(28vw / 7.68), 28px);
  }
  .paragraph {
    text-align: center;
    ul {
      text-align: left;
      > li {
        margin-top: 8px;
      }
    }
    max-width: calc(585rem / 16);
    margin: 0 auto clamp(16px, calc(28vw / 7.68), 28px);
  }

  .list {
    max-width: calc(585rem / 16);
    margin: 0 auto;
  }
}

.Checkbox {
  cursor: pointer;
  display: inline-grid;
  grid-template-columns: auto 1fr;
  width: fit-content;
  gap: 8px;
  border-radius: 2px;
  font-size: calc(14rem / 16);
  padding: 6px;
  .icon {
    width: 36px;
    height: 36px;
    border-radius: 2px;
    border: 1px solid var(--primary-400);
    display: grid;
    place-items: center;
    transition:
      background-color 0.5s,
      border-color 0.5s;
    input {
      position: absolute;
      opacity: 0;
    }
    svg {
      stroke-dasharray: 19;
      stroke-dashoffset: 19;
      transition: stroke-dashoffset 0.3s var(--easing);
    }
  }
  p {
    margin-top: 4px;
    > span[data-error] {
      margin-left: auto;
      margin-top: 4px;
    }
  }
  &:has(input:checked) {
    .icon {
      border-color: var(--primary-600);
      svg {
        stroke-dashoffset: 0;
      }
    }
  }
  &:has(input:focus-visible) {
    outline: 1px solid var(--primary-400);
    background-color: var(--primary-200);
  }
  &:hover {
    .icon {
      border-color: var(--primary-600);
      background-color: var(--primary-200);
    }
  }
  &[aria-invalid='true'] {
    .icon {
      border-color: var(--error-500);
    }
  }
}

.TableOfContent {
  display: grid;
  gap: clamp(36px, calc(36vw / 7.68), 80px);
  .chapter {
    border-bottom: 1px solid var(--primary-400);
    position: relative;
    padding-bottom: clamp(55px, calc(76vw / 7.68), 76px);
    .duration {
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: 0px 4px 0px 0px;
      background: var(--primary-400);
      padding: 8px 20px;
      font-size: calc(14rem / 16);
    }
    display: grid;
    gap: 32px;
    @media (min-width: 800px) {
      grid-template-columns: 1fr 1fr;
    }
    .header {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: clamp(8px, calc(16vw / 7.68), 16px);
      .headerBadge {
        width: clamp(60px, calc(80vw / 7.68), 80px);
        height: clamp(60px, calc(80vw / 7.68), 80px);
        border-radius: 50%;
        background: var(--primary-200);
        display: grid;
        gap: 4px;
        place-items: center;
        line-height: 1;
        span {
          &:first-child {
            color: var(--primary-700);
            font-size: calc(14rem / 16);
          }
          &:last-child {
            font-family: var(--font-city-streetwear);
            font-size: clamp(calc(32rem/16), calc(40vw/7.68), calc(40rem/16));
            line-height: 0;
          }
        }
      }
      h2 {
        margin-top: clamp(16px, calc(20vw / 7.68), 20px);
      }
    }
    .lessons {
      font-size: calc(14rem / 16);
      display: grid;
      gap: 12px;
      .item {
        display: grid;
        grid-template-columns: 60px 1fr;
        gap: clamp(24px, calc(32vw / 7.68), 32px);
        span {
          &:first-child {
            color: var(--primary-600);
          }
        }
      }
    }
  }
}

.MaterialsGroups {
  max-width: 637px;
  .heading {
    font-size: clamp(calc(24rem / 16), calc(36vw / 7.68), calc(36rem / 16));
    margin-bottom: 28px;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 36px;
    margin-bottom: 28px;
    font-weight: 300;
    line-height: 1.6;
    list-style: none;

    .group {
      &_heading {
        font-size: clamp(calc(16rem / 16), calc(18vw / 7.68), calc(18rem / 16));
        display: inline-block;
        margin-bottom: 16px;

        background: var(--primary-400, #e5d8d4);
        background: linear-gradient(rgba(255, 255, 255, 0) 50%, var(--primary-300) 50%);
        font-weight: 400;
      }

      &_list {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      &_additionalInfo {
        margin-top: 16px;
        font-size: clamp(calc(14rem / 16), calc(16vw / 7.68), calc(16rem / 16));
      }
      .item {
        display: flex;
        align-items: center;
        gap: 16px;

        &[data-additional-info='true'] {
          align-items: flex-start;
          > div:first-of-type {
            padding-top: 1px;
          }
        }

        .info {
          font-size: clamp(calc(14rem / 16), calc(14vw / 7.68), calc(14rem / 16));
          margin-top: 4px;
        }

        > span:first-of-type {
          display: grid;
          place-items: center;
          width: 32px;
          height: 32px;
          border-radius: 200px;
          background: var(--primary-300, #efe8e7);
          font-size: calc(14rem / 16);
          flex-shrink: 0;
        }

        > a,
        > span:last-of-type {
          font-size: clamp(calc(16rem / 16), calc(18vw / 7.68), calc(18rem / 16));
        }

        > a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .paragraph {
    line-height: 1.4;
    font-size: clamp(calc(18rem / 16), calc(24vw / 7.68), calc(24rem / 16));
  }
}

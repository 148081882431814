.TextSection {
  header {
    max-width: calc(584rem / 16);
    margin: 0 auto clamp(16px, calc(28vw / 7.68), 36px);
  }
  .column {
    display: grid;
    gap: clamp(16px, calc(20vw / 7.68), 32px);
    @media (min-width: 900px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
